import { graphql } from 'gatsby'
import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next'
import React from 'react'

import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const Disclaimer = () => {
  const { language } = useI18next()
  const { t } = useTranslation('pages', { keyPrefix: 'terms.disclaimer' })

  return (
    <>
      <SEO title={t('title')} />
      <div className="flex flex-col h-screen justify-between">
        <Navigation />
        <ContentMain>
          <HeaderTitle title={t('title')} />
          <ContentSubPage>
            <p>
              <Trans i18nKey="intro.text.0" t={t}>
                Valid from: January 01, 2020
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.1" t={t}>
                The information contained on{' '}
                <Link language={language} to="/">
                  https://www.flagis.com
                </Link>{' '}
                website and Flagis mobile app (the &quot;Service&quot;) is for
                general information purposes only.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.2" t={t}>
                Flagis s.r.o. assumes no responsibility for errors or omissions
                in the contents on the Service.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.3" t={t}>
                In no event shall Flagis s.r.o. be liable for any special,
                direct, indirect, consequential, or incidental damages or any
                damages whatsoever, whether in an action of contract, negligence
                or other arising out of or in connection with the use of the
                Service or the contents of the Service. Flagis s.r.o. reserves
                the right to make additions, deletions, or modification to the
                contents on the Service at any time without prior notice.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="intro.text.4" t={t}>
                Flagis s.r.o. does not warrant that the Service is free of
                viruses or other harmful components.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="externalLinks.title" t={t}>
                External links disclaimer
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="externalLinks.text.0" t={t}>
                <Link language={language} to="/">
                  https://www.flagis.com
                </Link>{' '}
                website and Flagis mobile app may contain links to external
                websites that are not provided or maintained by or in any way
                affiliated with Flagis s.r.o.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="externalLinks.text.1" t={t}>
                Please note that the Flagis s.r.o. does not guarantee the
                accuracy, relevance, timeliness, or completeness of any
                information on these external websites.
              </Trans>
            </p>
            <h3>
              <Trans i18nKey="contactUs.title" t={t}>
                Contact Us
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="contactUs.text.0" t={t}>
                If you have any questions about this Terms and Conditions,
                please contact us:
              </Trans>
            </p>
            <ul>
              <li>
                <Trans i18nKey="contactUs.text.1" t={t}>
                  By email: <a href="mailto:info@flagis.com">info@flagis.com</a>
                </Trans>
              </li>
              <li>
                <Trans i18nKey="contactUs.text.2" t={t}>
                  By visiting this page on our website:{' '}
                  <Link language={language} to="/contact-us">
                    https://www.flagis.com/contact-us
                  </Link>
                </Trans>
              </li>
            </ul>
          </ContentSubPage>
        </ContentMain>
        <Footer />
      </div>
      <CookiesBox />
    </>
  )
}

export default Disclaimer

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
